export default [
    { label: "16%", value: 2 },
    { label: "25%", value: 3 },
    { label: "33%", value: 4 },
    { label: "41%", value: 5 },
    { label: "50% (Half Screen)", value: 6 },
    { label: "58%", value: 7 },
    { label: "66%", value: 8 },
    { label: "75%", value: 9 },
    { label: "83%", value: 10 },
    { label: "100% (Full Screen)", value: 12 },
  ];