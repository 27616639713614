<template>
  <div v-if="dynamicFormsConfigurationShow == false">
    <filter-slot
      :filter="Filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['ListForms'].refresh()"
    >
      <b-table
        ref="ListForms"
        slot="table"
        primary-key="id"
        class="blue-scrollbar"
        empty-text="No matching records found"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDate }}
        </template>
        <template #cell(actions)="data">
          <feather-icon
            v-b-tooltip.hover.left="'Edit'"
            icon="SettingsIcon"
            size="18"
            class="cursor-pointer text-success mr-1"
            @click="ConfigureForm(data.item)"
          />
        </template>
      </b-table>
    </filter-slot>
  </div>
  <dynamic-forms-configuration
    v-else
    :id="formId"
    :name="formName"
    :moduleId = "currentModule"
    @close="dynamicFormsConfigurationShow = false"
  />
</template>

  <script>
import { mapGetters } from "vuex";
import Filters from "@/views/management/views/settings/views/dynamic_forms/data/dynamic.filters.js";
import DynamicFields from "@/views/management/views/settings/views/dynamic_forms/data/dynamic.fields.js";
import dynamicFormsConfiguration from "@/views/management/views/settings/views/dynamic_forms/components/DynamicFormsConfiguration.vue";
import ServicesForms from "@/views/management/views/settings/views/dynamic_forms/services/Form.service.js";
export default {
  filters: {
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
  components: {
    dynamicFormsConfiguration,
  },
  data() {
    return {
      dynamicFormsConfigurationShow: false,
      Filters,
      fields: DynamicFields,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by ......",
        model: "",
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
    };
  },
  computed: {
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async myProvider(ctx) {
      this.addPreloader();

      let order = ctx.sortDesc ? "asc" : "desc";
      let order_by = ctx.sortBy ? ctx.sortBy : "id";
      order = ctx.sortBy ? order : "desc";
      try {
        const { data } = await ServicesForms.getFormsDynamics({
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          search: this.filterPrincipal.model,
          orden: order,
          orderby: order_by,
          from: Filters[0].model,
          to: Filters[1].model,
          module_id: this.currentModule,
        });
        const items = data;
        this.paginate.currentPage = items.current_page;
        this.paginate.perPage = items.per_page;
        this.totalRows = items.total;
        this.startPage = items.from;
        this.toPage = items.to;
        return items.data || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async ConfigureForm(item) {
      this.dynamicFormsConfigurationShow = true;
      this.formId = item.id;
      this.formName = item.name;
    },
  },
};
</script>
