<template>
  <div class="mg-forms-dinamic mb-1">
    <div class="forms-dinamic" @mouseup="isDrag = true">
      <b-card
        class="container"
        :class="sections.length < 1 && isSection == false ? 'empty-forms' : ''"
      >
        <!-- actions: cancel and save configuration -->
        <div
          class="mb-1 w-100"
          data-v-step="0"
          :class="tutorial == 'show' ? 'pt-2' : ''"
          :style="
            sections.length < 1 && isSection == false
              ? 'width:50% !important'
              : ''
          "
        >
          <div>
            <b-alert class="p-2" show>
              <strong class="text-uppercase">{{ name }}:</strong> The sections
              and fields that you are about to create will appear below the
              existing fields.
            </b-alert>
          </div>
          <div
            class="d-flex"
            :class="
              sections.length < 1 && isSection == false
                ? 'justify-content-center'
                : 'justify-content-between'
            "
          >
            <div>
              <b-form-radio-group
                v-if="isSettingsSection && name !== 'Departments'"
                button-variant="outline-primary"
                v-model="selectedCountry"
                :options="[
                  { text: 'USA', value: 'US' },
                  { text: 'PERU', value: 'PE' },
                ]"
                buttons
                @change="GET_CUSTOM_FORM_BY_ID"
              >
              </b-form-radio-group>
            </div>
            <div>
              <b-button
                v-if="isSettingsSection"
                variant="outline-danger"
                class="mr-1"
                @click="closeConfiguration"
                >Cancel</b-button
              >
              <b-button
                variant="outline-primary"
                @click="SAVE_CONFIGURATION_FORM"
                >Save</b-button
              >
            </div>
          </div>
        </div>
        <!-- Preview of components and sections -->
        <draggable
          tag="div"
          :list="sections"
          v-bind="dragOptions"
          @end="onDragEnd()"
        >
          <transition-group type="transition" name="flip-list">
            <b-card
              class="cursor-pointer sections-dinamic border"
              v-for="(section, indexs) in sections"
              :key="section.title"
              :class="{
                'border-active-section': selectSection == section.title,
                noDrag: section.slug != null,
                disabled: isDrag || section.slug == null,
              }"
            >
              <!-- :class="
                selectSection
                  ? selectSection == section.title
                    ? 'border-active-section'
                    : 'border'
                  : 'border'
              " -->
              <template #header>
                <b-card-title
                  :class="section.variant ? section.variant : ''"
                  class="d-flex justify-content-between w-100"
                >
                  <strong>
                    <feather-icon
                      :icon="section.icon ? section.icon : ''"
                      size="18"
                      class="mr-1"
                    />
                    {{ section.title }}
                  </strong>
                  <!-- actions: delete and update secctions -->
                  <div v-if="section.new_section == 1" class="section-actions">
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="mr-1 text-primary"
                      v-b-tooltip.hover="'Edit section'"
                      @click="showSectionSettings(indexs)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="text-danger"
                      v-b-tooltip.hover="'Delete section'"
                      @click="deleteSection(section.title,indexs)"
                    />
                  </div>
                </b-card-title>
              </template>
              <template #default>
                <h4 v-if="section.subtitle" class="mb-1">
                  <strong>{{ section.subtitle }}</strong>
                </h4>
                <draggable
                  tag="div"
                  :list="section.components"
                  v-bind="dragOptionsCols"
                  @end="onDragEnd()"
                  :key="indexs"
                  handle=".handle"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                    tag="div"
                    class="row"
                  >
                    <template v-for="(component, index) in section.components">
                      <b-col
                        cols="12"
                        sm="12"
                        :md="component.col"
                        :lg="component.col"
                        :key="index"
                        class="d-flex justify-content-between components-dinamic"
                        :class="{
                          'border-active rounded':
                            indiceEdit !== null &&
                            index == indiceEdit &&
                            section.title == currentSection,
                          yesDrag: isDrag || section.slug == null,
                        }"
                        :style="
                          shouldShowComponent(component)
                            ? ''
                            : { display: 'none !important' }
                        "
                        :ref="component.key"
                      >
                        <feather-icon
                          icon="MoveIcon"
                          size="20"
                          class="mr-1 h-100 handle"
                        />
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="buildRules(component)"
                          class="flex-fill"
                          tag="div"
                          v-if="shouldRenderFormGroup(component)"
                        >
                          <b-form-group>
                            <div
                              id="divId"
                              @mousedown="isDrag = false"
                              @mouseup="isDrag = true"
                            >
                              <div
                                class="d-flex justify-content-between"
                                style="margin-bottom: 4px"
                              >
                                <label>{{ component.label }}</label>
                                <div
                                  v-if="component.new_component == 1"
                                  class="component-actions d-flex align-items-center"
                                >
                                  <div>
                                    <feather-icon
                                      icon="SettingsIcon"
                                      size="16"
                                      class="mr-1 text-primary yesDrag"
                                      v-b-tooltip.hover="
                                        'Edit field configuration'
                                      "
                                      @click="
                                        showComponetSettings(
                                          index,
                                          section.title,
                                          indexs
                                        )
                                      "
                                      @mousedown="isDrag = false"
                                      @mouseup="isDrag = true"
                                    />
                                  </div>
                                  <div>
                                    <feather-icon
                                      icon="TrashIcon"
                                      size="16"
                                      class="text-danger yesDrag"
                                      v-b-tooltip.hover="'Delete field'"
                                      @click="deleteComponent(component.label,index, indexs)"
                                      @mousedown="isDrag = false"
                                      @mouseup="isDrag = true"
                                    />
                                  </div>

                                  <SwitchCustom
                                    v-if="name != 'Departments'"
                                    :isChecked="component.visible"
                                    @update:checked="
                                      updateChecked(component, $event)
                                    "
                                    :idCustom="component.key"
                                    class="ml-1"
                                  />
                                </div>
                              </div>
                              <component
                                :id="component.key"
                                :key="component.key"
                                :is="component.is"
                                :label="
                                  component.options != []
                                    ? 'text'
                                    : component.label
                                "
                                :options="
                                  component.type == 'quill_editor'
                                    ? { modules: { toolbar: true } }
                                    : component.options
                                "
                                :reduce="(option) => option.value"
                                :getOptionKey="(option) => option.value"
                                :multiple="component.multiple"
                                :type="component.type"
                                :placeholder="component.placeholder"
                                :date-format-options="
                                  component.type == 'date'
                                    ? {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                      }
                                    : false
                                "
                                :files-array="
                                  component.type == 'drag_and_drop' ? [] : false
                                "
                                :switch="
                                  component.type == 'switch' ? true : false
                                "
                                :class="
                                  component.type == 'switch'
                                    ? 'custom-control-primary'
                                    : errors[0]
                                    ? 'border-danger rounded'
                                    : ''
                                "
                                :name="
                                  component.type == 'switch'
                                    ? 'check-button'
                                    : ''
                                "
                                :value="
                                  component.type == 'switch'
                                    ? component.options[0].value
                                    : ''
                                "
                                :unchecked-value="0"
                                v-model="component.model"
                                :source="'drag-and-drop-' + index"
                                :state="errors[0] ? false : null"
                                @input="
                                  componentAcctions(
                                    component.model,
                                    component.key,
                                    component.is_parent
                                  )
                                "
                              />
                              <span v-if="errors[0]" class="text-danger"
                                >{{ component.label }} {{ errors[0] }}
                              </span>
                            </div>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          v-slot="{ errors }"
                          :rules="buildRules(component)"
                          class="flex-fill"
                          tag="div"
                          v-if="component.type == 'money'"
                        >
                          <b-form-group>
                            <div
                              class="d-flex justify-content-between"
                              style="margin-bottom: 4px"
                            >
                              <label>{{ component.label }}</label>
                              <div
                                v-if="component.new_component == 1"
                                class="component-actions d-flex"
                              >
                                <div>
                                  <feather-icon
                                    icon="SettingsIcon"
                                    size="16"
                                    class="mr-1 text-primary yesDrag"
                                    v-b-tooltip.hover="
                                      'Edit field configuration'
                                    "
                                    @click="
                                      showComponetSettings(
                                        index,
                                        section.title,
                                        indexs
                                      )
                                    "
                                  />
                                </div>
                                <div>
                                  <feather-icon
                                    icon="TrashIcon"
                                    size="16"
                                    class="text-danger yesDrag"
                                    v-b-tooltip.hover="'Delete field'"
                                    @click="deleteComponent(component.label,index, indexs)"
                                  />
                                </div>

                                <SwitchCustom
                                  v-if="name != 'Departments'"
                                  :isChecked="component.visible"
                                  @update:checked="
                                    updateChecked(component, $event)
                                  "
                                  :idCustom="component.key"
                                  class="ml-1"
                                />
                              </div>
                            </div>
                            <money
                              v-bind="{
                                maxlength: 16,
                                decimal: '.',
                                thousands: ',',
                                prefix: component.format + ' ',
                                precision: 2,
                                min: 0.0,
                                masked: false,
                              }"
                              class="form-control yesDrag"
                              :state="errors[0] ? false : null"
                              v-model="component.model"
                            />
                            <span v-if="errors[0]" class="text-danger"
                              >{{ component.label }} {{ errors[0] }}
                            </span>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          v-slot="{ errors }"
                          :rules="buildRules(component)"
                          class="flex-fill"
                          tag="div"
                          v-if="component.type == 'text' && component.format"
                        >
                          <b-form-group>
                            <div
                              class="d-flex justify-content-between"
                              style="margin-bottom: 4px"
                            >
                              <label>{{ component.label }}</label>
                              <div
                                v-if="component.new_component == 1"
                                class="component-actions d-flex"
                              >
                                <div>
                                  <feather-icon
                                    icon="SettingsIcon"
                                    size="16"
                                    class="mr-1 text-primary yesDrag"
                                    v-b-tooltip.hover="
                                      'Edit field configuration'
                                    "
                                    @click="
                                      showComponetSettings(
                                        index,
                                        section.title,
                                        indexs
                                      )
                                    "
                                  />
                                </div>

                                <div>
                                  <feather-icon
                                    icon="TrashIcon"
                                    size="16"
                                    class="text-danger yesDrag"
                                    v-b-tooltip.hover="'Delete field'"
                                    @click="deleteComponent(component.label,index, indexs)"
                                  />
                                </div>

                                <SwitchCustom
                                  v-if="name != 'Departments'"
                                  :isChecked="component.visible"
                                  @update:checked="
                                    updateChecked(component, $event)
                                  "
                                  :idCustom="component.key"
                                  class="ml-1"
                                />
                              </div>
                            </div>
                            <b-form-input
                              :placeholder="component.placeholder"
                              :masked="component.format ? true : false"
                              v-mask="component.format"
                              :state="errors[0] ? false : null"
                              v-model="component.model"
                            />
                            <span v-if="errors[0]" class="text-danger"
                              >{{ component.label }} {{ errors[0] }}
                            </span>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          v-slot="{ errors }"
                          :rules="buildRules(component)"
                          class="flex-fill"
                          tag="div"
                          v-if="component.type == 'address'"
                        >
                          <b-form-group>
                            <div
                              class="d-flex justify-content-between"
                              style="margin-bottom: 4px"
                            >
                              <label>{{ component.label }}</label>
                              <div
                                v-if="component.new_component == 1"
                                class="component-actions d-flex"
                              >
                                <div>
                                  <feather-icon
                                    icon="SettingsIcon"
                                    size="16"
                                    class="mr-1 text-primary yesDrag"
                                    v-b-tooltip.hover="
                                      'Edit field configuration'
                                    "
                                    @click="
                                      showComponetSettings(
                                        index,
                                        section.title,
                                        indexs
                                      )
                                    "
                                  />
                                </div>

                                <div>
                                  <feather-icon
                                    icon="TrashIcon"
                                    size="16"
                                    class="text-danger yesDrag"
                                    v-b-tooltip.hover="'Delete field'"
                                    @click="deleteComponent(component.label,index, indexs)"
                                  />
                                </div>

                                <SwitchCustom
                                  v-if="name != 'Departments'"
                                  :isChecked="component.visible"
                                  @update:checked="
                                    updateChecked(component, $event)
                                  "
                                  :idCustom="component.key"
                                  class="ml-1"
                                />
                              </div>
                            </div>
                            <vue-google-autocomplete
                              :id="component.key"
                              :ref="component.key"
                              classname="input-form form-control fond-white"
                              :placeholder="component.placeholder"
                              country="us"
                              style="height: 35px !important"
                              :state="errors[0] ? false : null"
                              v-model="component.model"
                              v-on:placechanged="
                                (address, placeResultData) =>
                                  getAddressData(
                                    address,
                                    placeResultData,
                                    component
                                  )
                              "
                              maxlength="100"
                              :style="
                                errors[0]
                                  ? 'border:1px solid red !important;'
                                  : ''
                              "
                              :class="
                                errors[0] ? 'rounded border border-danger' : ''
                              "
                            />
                            <span v-if="errors[0]" class="text-danger"
                              >{{ component.label }} {{ errors[0] }}
                            </span>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          v-slot="{ errors }"
                          :rules="buildRules(component)"
                          class="flex-fill"
                          tag="div"
                          v-if="component.type == 'checkbox'"
                        >
                          <b-form-group v-slot="{ ariaDescribedby }">
                            <div
                              class="d-flex justify-content-between"
                              style="margin-bottom: 4px"
                            >
                              <label>{{ component.label }}</label>
                              <div
                                v-if="component.new_component == 1"
                                class="component-actions d-flex"
                              >
                                <div>
                                  <feather-icon
                                    icon="SettingsIcon"
                                    size="16"
                                    class="mr-1 text-primary yesDrag"
                                    v-b-tooltip.hover="
                                      'Edit field configuration'
                                    "
                                    @click="
                                      showComponetSettings(
                                        index,
                                        section.title,
                                        indexs
                                      )
                                    "
                                  />
                                </div>

                                <div>
                                  <feather-icon
                                    icon="TrashIcon"
                                    size="16"
                                    class="text-danger yesDrag"
                                    v-b-tooltip.hover="'Delete field'"
                                    @click="deleteComponent(component.label,index, indexs)"
                                  />
                                </div>

                                <SwitchCustom
                                  v-if="name != 'Departments'"
                                  :isChecked="component.visible"
                                  @update:checked="
                                    updateChecked(component, $event)
                                  "
                                  :idCustom="component.key"
                                  class="ml-1"
                                />
                              </div>
                            </div>
                            <b-form-checkbox-group
                              :id="component.key"
                              :options="component.options"
                              :aria-describedby="ariaDescribedby"
                              name="flavour-1"
                              v-model="component.model"
                              :state="errors[0] ? false : null"
                            ></b-form-checkbox-group>
                          </b-form-group>
                          <span v-if="errors[0]" class="text-danger"
                            >{{ component.label }} {{ errors[0] }}
                          </span>
                        </validation-provider>

                        <validation-provider
                          v-slot="{ errors }"
                          :rules="buildRules(component)"
                          class="flex-fill"
                          v-if="component.type == 'radio'"
                        >
                          <b-form-group v-slot="{ ariaDescribedby }">
                            <div
                              class="d-flex justify-content-between"
                              style="margin-bottom: 4px"
                            >
                              <label>{{ component.label }}</label>
                              <div
                                v-if="component.new_component == 1"
                                class="component-actions d-flex"
                              >
                                <div>
                                  <feather-icon
                                    icon="SettingsIcon"
                                    size="16"
                                    class="mr-1 text-primary yesDrag"
                                    v-b-tooltip.hover="
                                      'Edit field configuration'
                                    "
                                    @click="
                                      showComponetSettings(
                                        index,
                                        section.title,
                                        indexs
                                      )
                                    "
                                  />
                                </div>

                                <div>
                                  <feather-icon
                                    icon="TrashIcon"
                                    size="16"
                                    class="text-danger yesDrag"
                                    v-b-tooltip.hover="'Delete field'"
                                    @click="deleteComponent(component.label,index, indexs)"
                                  />
                                </div>

                                <SwitchCustom
                                  v-if="name != 'Departments'"
                                  :isChecked="component.visible"
                                  @update:checked="
                                    updateChecked(component, $event)
                                  "
                                  :idCustom="component.key"
                                  class="ml-1 yesDrag"
                                />
                              </div>
                            </div>
                            <b-form-radio-group
                              :id="component.key"
                              :options="component.options"
                              :aria-describedby="ariaDescribedby"
                              :name="component.key"
                              :state="errors[0] ? false : null"
                              v-model="component.model"
                              @input="
                                componentAcctions(
                                  component.model,
                                  component.key,
                                  component.is_parent
                                )
                              "
                            ></b-form-radio-group>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </template>
                    <!-- Previewing a component's configuration before adding to a section-->
                    <b-col
                      class="border-active configure-section rounded"
                      :key="component.key"
                      cols="12"
                      :sm="component.col"
                      v-if="
                        componentNameSelected &&
                        isSection == false &&
                        indiceEdit == null &&
                        selectSection == section.title &&
                        sections.length > 0
                      "
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="buildRules(component)"
                      >
                        <b-form-group v-if="shouldRenderFormGroup(component)">
                          <div class="d-flex justify-content-between">
                            <label :for="component.label">{{
                              component.label
                            }}</label>
                            <SwitchCustom
                              v-if="name != 'Departments'"
                              :isChecked="component.visible"
                              @update:checked="updateChecked(component, $event)"
                              :idCustom="component.key"
                              class="ml-1"
                            />
                          </div>
                          <component
                            :id="component.key"
                            :key="component.key"
                            :is="component.is"
                            :label="
                              component.options != [] ? 'text' : component.label
                            "
                            :options="
                              component.type == 'quill_editor'
                                ? { modules: { toolbar: true } }
                                : component.options
                            "
                            :reduce="(option) => option.value"
                            :getOptionKey="(option) => option.value"
                            :multiple="component.multiple"
                            :type="component.type"
                            :placeholder="component.placeholder"
                            :date-format-options="
                              component.type == 'date'
                                ? {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                  }
                                : false
                            "
                            :files-array="
                              component.type == 'drag_and_drop' ? [] : false
                            "
                            :switch="component.type == 'switch' ? true : false"
                            :class="
                              component.type == 'switch'
                                ? 'custom-control-primary'
                                : errors[0]
                                ? 'border-danger rounded'
                                : ''
                            "
                            :name="
                              component.type == 'switch' ? 'check-button' : ''
                            "
                            v-model="component.model"
                            :state="errors[0] ? false : null"
                          />
                          <span v-if="errors[0]" class="text-danger"
                            >{{ component.label }} {{ errors[0] }}
                          </span>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        :rules="buildRules(component)"
                      >
                        <b-form-group v-if="component.type == 'money'">
                          <label :for="component.label">{{
                            component.label
                          }}</label>
                          <money
                            v-bind="{
                              maxlength: 16,
                              decimal: '.',
                              thousands: ',',
                              prefix: component.format + ' ',
                              precision: 2,
                              min: 0.0,
                              masked: false,
                            }"
                            class="form-control"
                            :state="errors[0] ? false : null"
                            v-model="component.model"
                          />
                          <span v-if="errors[0]" class="text-danger"
                            >{{ component.label }} {{ errors[0] }}
                          </span>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        :rules="buildRules(component)"
                      >
                        <b-form-group
                          v-if="component.type == 'text' && component.format"
                        >
                          <label :for="component.label">{{
                            component.label
                          }}</label>
                          <b-form-input
                            :placeholder="component.placeholder"
                            :masked="component.format ? true : false"
                            v-mask="component.format"
                            :state="errors[0] ? false : null"
                            v-model="component.model"
                          />
                          <span v-if="errors[0]" class="text-danger"
                            >{{ component.label }} {{ errors[0] }}
                          </span>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        :rules="buildRules(component)"
                      >
                        <b-form-group v-if="component.type == 'address'">
                          <label :for="component.label">{{
                            component.label
                          }}</label>
                          <vue-google-autocomplete
                            :id="'prev-' + component.key"
                            classname="input-form form-control fond-white"
                            :placeholder="component.placeholder"
                            country="us"
                            style="height: 35px !important"
                            :state="errors[0] ? false : null"
                            v-model="component.model"
                            maxlength="100"
                            :style="
                              errors[0]
                                ? 'border:1px solid red !important;'
                                : ''
                            "
                            :class="
                              errors[0] ? 'rounded border border-danger' : ''
                            "
                          />
                          <span v-if="errors[0]" class="text-danger"
                            >{{ component.label }} {{ errors[0] }}
                          </span>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        :rules="buildRules(component)"
                      >
                        <b-form-group
                          v-slot="{ ariaDescribedby }"
                          v-if="component.type == 'checkbox'"
                        >
                          <label :for="component.label">{{
                            component.label
                          }}</label>
                          <b-form-checkbox-group
                            :id="component.key"
                            :options="component.options"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                            :state="errors[0] ? false : null"
                            v-model="component.model"
                          ></b-form-checkbox-group>
                          <span v-if="errors[0]" class="text-danger"
                            >{{ component.label }} {{ errors[0] }}
                          </span>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        :rules="buildRules(component)"
                      >
                        <b-form-group
                          v-slot="{ ariaDescribedby }"
                          v-if="component.type == 'radio'"
                        >
                          <label :for="component.label">{{
                            component.label
                          }}</label>
                          <b-form-radio-group
                            :id="component.key"
                            :options="component.options"
                            :aria-describedby="ariaDescribedby"
                            :name="component.key"
                            :state="errors[0] ? false : null"
                            v-model="component.model"
                          ></b-form-radio-group>
                          <span v-if="errors[0]" class="text-danger"
                            >{{ component.label }} {{ errors[0] }}
                          </span>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </transition-group>
                </draggable>
              </template>
            </b-card>
          </transition-group>
        </draggable>
        <!-- Previewing the configuration of a section before adding to the array-->
        <b-card
          :class="{ 'configure-section border-active-section': isSection }"
          v-if="isSection && indiceEdit == null"
        >
          <template #header>
            <b-card-title :class="section.variant ? section.variant : ''">
              <strong>
                <feather-icon
                  :icon="section.icon ? section.icon : ''"
                  size="18"
                  class="mr-1"
                />
                {{ section.title }}</strong
              >
            </b-card-title>
          </template>
          <template #default>
            <h4 v-if="section.subtitle" class="mb-1">
              <strong>{{ section.subtitle }}</strong>
            </h4>
          </template>
        </b-card>
      </b-card>
    </div>
    <!-- Toolbox -->
    <div class="toolbox">
      <b-card class="container">
        <div>
          <p class="title-forms-dinamic">
            <strong>CUSTOM FIELDS</strong>
          </p>

          <p></p>
          <p><strong>CREATE A FIELD</strong></p>
          <p>
            Click on a icon to create a custom field and add inside a section.
          </p>
        </div>
        <!-- listado de componentes -->
        <div class="fields mt-1" v-if="componentNameSelected == null">
          <div
            class="field border p-1 cursor-pointer d-flex align-items-center flex-column justify-content-center"
            @click="configureNewSection()"
            :class="sections.length < 1 ? 'active animation' : ''"
            data-v-step="1"
          >
            <toolbox-icon :icon-name="'new-section'" />
            <strong class="text-center">Section</strong>
          </div>
          <div
            v-for="(component, index) in components"
            :key="index"
            class="field border p-1 cursor-pointer d-flex align-items-center flex-column justify-content-center"
            @click="configureNewComponent(component.id)"
            data-v-step="4"
          >
            <toolbox-icon :icon-name="component.name" />
            <strong class="text-capitalize text-center">{{
              component.name | removeHyphens
            }}</strong>
          </div>
        </div>
        <!-- configuracion de una seccion o componente -->
        <div class="mt-1" v-else>
          <div class="fields mb-1">
            <div
              class="field border p-1 cursor-pointer d-flex align-items-center flex-column justify-content-center active"
            >
              <toolbox-icon :icon-name="componentNameSelected" />
              <strong class="text-capitalize text-center">{{
                componentNameSelected | removeHyphens
              }}</strong>
            </div>
          </div>
          <div>
            <p>
              <strong>
                <span ref="isSection"></span>
                <span ref="isComponent"></span
              ></strong>
            </p>
            <p v-if="isSection">
              You can drag and drop sections to organize them.
            </p>
            <p v-else>
              In the section you can drag and drop fields to organize them.
            </p>
          </div>
          <b-card class="border">
            <validation-observer #default="{ invalid }">
              <div class="d-flex justify-content-end">
                <b-button
                  class="p-0 btn-sm border-0 mr-1"
                  style="background-color: transparent !important"
                  :class="
                    invalid ||
                    titleSectionIsUnique == false ||
                    (conditionsActive && hasContidions == false)
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  "
                  @click="
                    isSection
                      ? saveSection(optionSaveSection)
                      : saveComponent(optionSaveComponent)
                  "
                  :disabled="
                    invalid ||
                    titleSectionIsUnique == false ||
                    (conditionsActive && hasContidions == false)
                  "
                  v-b-tooltip.hover="'Save configuration'"
                  data-v-step="3"
                >
                  <feather-icon
                    icon="SaveIcon"
                    size="18"
                    class="text-primary"
                  />
                </b-button>

                <feather-icon
                  icon="XIcon"
                  size="18"
                  v-b-tooltip.hover="'Discard configuration'"
                  class="cursor-pointer text-danger"
                  @click="cancel()"
                />
              </div>
              <!-- configure sections -->
              <div v-if="isSection" data-v-step="2">
                <b-form-group label="Title Color">
                  <v-select
                    v-model="section.variant"
                    :clearable="true"
                    label="label"
                    :options="variantOptions"
                    :reduce="(option) => option.value"
                    placeholder="Select a color"
                  />
                </b-form-group>
                <validation-provider v-slot="{ errors }" rules="required">
                  <b-form-group label="Title">
                    <b-form-input
                      v-model="section.title"
                      placeholder="Title"
                      @input="validateSectionTitle"
                      :state="
                        errors[0] || titleSectionIsUnique == false
                          ? false
                          : null
                      "
                      trim
                      maxlength="255"
                    />
                    <span
                      v-if="titleSectionIsUnique == false"
                      class="text-danger"
                      >Section title already exists</span
                    >
                  </b-form-group>
                </validation-provider>

                <b-form-group label="Subtitle">
                  <b-form-input
                    v-model="section.subtitle"
                    placeholder="Subtitle"
                    maxlength="255"
                  />
                </b-form-group>

                <b-form-group label="Icon">
                  <v-select
                    v-model="section.icon"
                    :clearable="true"
                    label="label"
                    :options="icons"
                    :reduce="(option) => option.value"
                    placeholder="Select a icon"
                  >
                    <template #option="{ value, label }">
                      <feather-icon :icon="value" size="18" class="mr-1" />
                      {{ label }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <!-- configure components -->
              <div v-else data-v-step="5">
                <validation-provider v-slot="{ errors }" rules="required">
                  <b-form-group label="Section">
                    <v-select
                      v-model="selectSection"
                      :clearable="true"
                      label="title"
                      :options="sections"
                      :reduce="(option) => option.title"
                      placeholder="Select a section"
                      :state="errors[0] ? false : null"
                      :class="errors[0] ? 'border-danger' : ''"
                    />
                  </b-form-group>
                </validation-provider>

                <validation-provider v-slot="{ errors }" rules="required">
                  <b-form-group label="Size">
                    <v-select
                      v-model="component.col"
                      :clearable="true"
                      label="label"
                      :options="colOptions"
                      :reduce="(option) => option.value"
                      placeholder="Select size"
                      :state="errors[0] ? false : null"
                      :class="errors[0] ? 'border-danger' : ''"
                    />
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  v-if="!conditionsActive"
                >
                  <b-form-group label="Label">
                    <b-form-input
                      v-model="component.label"
                      @input="labelComponentAcctions"
                      placeholder="Label"
                      :state="errors[0] ? false : null"
                      trim
                      maxlength="255"
                    />
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  label="Add Format"
                  v-if="component.type == 'text'"
                >
                  <b-form-checkbox
                    v-model="formatActive"
                    @change="addFormat"
                    switch
                  />
                </b-form-group>

                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  v-if="component.type == 'text' && formatActive"
                >
                  <b-form-group
                    label="Format"
                    v-b-tooltip.hover="'Allowed characters: # ( ) - $'"
                  >
                    <b-form-input
                      v-model="component.format"
                      placeholder="Example: ###-###-####"
                      :state="errors[0] ? false : null"
                      @input="validateFormat"
                    />
                  </b-form-group>
                </validation-provider>

                <!-- only component select -->
                <div v-if="component.type == 'select'">
                  <b-form-group label="Multiple">
                    <b-form-checkbox v-model="component.multiple" switch />
                  </b-form-group>
                </div>

                <div v-if="componentHasOptions">
                  <div>
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="component.options.length < 1 ? 'required' : ''"
                    >
                      <div class="d-flex justify-content-between">
                        <label>Add Options</label>
                        <b-button
                          variant="outline"
                          v-b-tooltip.hover-right="'Add Option'"
                          class="p-0 m-0 cursor-pointer"
                          @click="addOptionsComponent()"
                          :disabled="optionsComponent.text == null"
                        >
                          <feather-icon
                            icon="PlusSquareIcon"
                            size="18"
                            class="text-primary"
                          />
                        </b-button>
                      </div>

                      <b-form-input
                        style="margin-top: 4px"
                        v-model="optionsComponent.text"
                        placeholder="Enter option"
                        :state="errors[0] ? false : null"
                        @keydown.enter="addOptionsComponent()"
                        trim
                      />
                    </validation-provider>
                  </div>
                  <div class="mt-1">
                    <div
                      v-for="(option, index) in component.options"
                      :key="index"
                      class="d-flex justify-content-between align-items-center border rounded mb-1"
                      style="padding: 8px 10px"
                    >
                      {{ option.text }}
                      <feather-icon
                        icon="XIcon"
                        size="18"
                        class="text-danger cursor-pointer"
                        v-b-tooltip.hover-right="'Delete Options'"
                        @click="deleteOptionsComponent(index)"
                      />
                    </div>
                  </div>
                </div>

                <!-- validations -->
                <b-form-group label="Add Validations">
                  <b-form-checkbox
                    v-model="validationsActive"
                    @change="discardValidations"
                    switch
                  />
                </b-form-group>

                <div v-if="validationsActive">
                  <div
                    class="d-flex justify-content-between"
                    v-for="(rule, index) in validationsByComponentType"
                    :key="index"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center border rounded mb-1 w-100"
                      style="padding: 8px 10px; gap: 10px"
                      v-if="!isRuleInValidations(rule)"
                    >
                      <div class="d-flex align-items-center">
                        <span class="text-capitalize mr-1">
                          {{
                            rule.type == "min"
                              ? "Min length"
                              : rule.type == "max"
                              ? "Max length"
                              : rule.type | removeHyphens
                          }}
                        </span>

                        <b-form-input
                          v-if="
                            ['min', 'max', 'min_value', 'max_value'].includes(
                              rule.type
                            )
                          "
                          :ref="'ruleValue' + rule.id"
                          type="number"
                          placeholder="Enter value"
                          style="width: 100px"
                          @keydown.enter="
                            addValidationsComponent(rule.id, rule.type)
                          "
                          trim
                        />
                      </div>

                      <b-button
                        variant="outline"
                        v-b-tooltip.hover-right="'Add Validation'"
                        class="p-0 m-0 cursor-pointer"
                        @click="addValidationsComponent(rule.id, rule.type)"
                      >
                        <feather-icon
                          icon="PlusSquareIcon"
                          size="18"
                          class="text-primary"
                        />
                      </b-button>
                    </div>
                  </div>
                  <div>
                    <div
                      v-for="(validation, index) in component.validations"
                      :key="index"
                      class="d-flex justify-content-between align-items-center border rounded mb-1 w-100"
                      style="padding: 8px 10px; gap: 10px"
                    >
                      <div class="d-flex align-items-center">
                        <span class="text-capitalize mr-1">
                          {{
                            validation.type == "min"
                              ? "Min length"
                              : validation.type == "max"
                              ? "Max length"
                              : validation.type | removeHyphens
                          }}
                        </span>
                        <span
                          v-if="
                            ['min', 'max', 'min_value', 'max_value'].includes(
                              validation.type
                            )
                          "
                          >: {{ validation.value }}</span
                        >
                      </div>

                      <feather-icon
                        icon="XIcon"
                        size="18"
                        class="text-danger cursor-pointer"
                        v-b-tooltip.hover-right="'Delete Validation'"
                        @click="deleteValidationsComponent(index)"
                      />
                    </div>
                  </div>
                </div>

                <!-- conditions -->
                <b-row>
                  <b-col
                    cols="6"
                    v-if="
                      component.type == 'select' ||
                      component.type == 'radio' ||
                      component.type == 'switch'
                    "
                  >
                    <b-form-group
                      label="Use for conditions"
                      v-b-tooltip.hover-right="
                        'This field controls the visibility of other fields'
                      "
                    >
                      <b-form-checkbox v-model="component.is_parent" switch />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" v-if="parentComponents.length > 0">
                    <b-form-group label="Add Conditions">
                      <b-form-checkbox
                        v-model="conditionsActive"
                        @change="discardConditions"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <div v-if="conditionsActive && parentComponents.length > 0">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-group label="Linked Fields">
                      <v-select
                        v-model="selectParentComponent"
                        :clearable="true"
                        label="label"
                        :options="parentComponents"
                        :reduce="(option) => option.key"
                        :getOptionKey="(option) => option.key"
                        placeholder="Select a Linked Fields"
                        @input="selectedParent"
                        :state="errors[0] ? false : null"
                        :class="errors[0] ? 'border-danger' : ''"
                      >
                      </v-select>
                    </b-form-group>
                  </validation-provider>

                  <div v-if="optionsParentComponent">
                    <template
                      v-for="(condition, index) in optionsParentComponent"
                    >
                      <div
                        class="border rounded mb-1"
                        :key="index + component.key"
                        style="padding: 8px 10px"
                        v-if="!isConditionInComponent(condition)"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center w-100"
                          style="gap: 10px"
                        >
                          <div class="d-flex align-items-center">
                            <span class="text-capitalize mr-1">
                              Show on select:
                              <strong>{{ condition.text }}</strong>
                            </span>
                          </div>

                          <b-button
                            variant="outline"
                            v-b-tooltip.hover-right="'Add Condition'"
                            class="p-0 m-0 cursor-pointer"
                            @click="addConditionsComponent(condition.value)"
                          >
                            <feather-icon
                              icon="PlusSquareIcon"
                              size="18"
                              class="text-primary"
                            />
                          </b-button>
                        </div>
                        <b-form-group label="Label" :key="condition.value">
                          <b-form-input
                            @keydown.enter="
                              addConditionsComponent(condition.value)
                            "
                            :ref="'condition-' + condition.value"
                            placeholder="Label"
                            maxlength="255"
                            trim
                          />
                        </b-form-group>
                      </div>
                    </template>

                    <div
                      class="border rounded mb-1"
                      v-for="(condition, index) in component.parent[0]
                        .conditions"
                      :key="index"
                      style="padding: 8px 10px"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center w-100"
                        style="gap: 10px"
                      >
                        <div class="d-flex align-items-center">
                          <span class="text-capitalize mr-1">
                            Show on select:
                            <strong>{{
                              findValueCondition(condition, component)
                            }}</strong>
                          </span>
                        </div>

                        <b-button
                          variant="outline"
                          v-b-tooltip.hover-right="'Delete Condition'"
                          class="p-0 m-0 cursor-pointer"
                          @click="deleteConditionComponent(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            size="18"
                            class="text-danger"
                          />
                        </b-button>
                      </div>
                      <b-form-group label="Label" :key="index">
                        <b-form-input
                          :value="condition.label"
                          :disabled="true"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </validation-observer>
          </b-card>
        </div>
      </b-card>
    </div>
    <!-- component creation tutorial -->
    <v-tour
      name="myTour"
      :steps="steps"
      :callbacks="myCallbacks"
      :options="myOptionsTour"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :finish="tour.finish"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
          >
            <template
              v-if="
                tour.currentStep === 2 ||
                tour.currentStep === 4 ||
                tour.currentStep === 5
              "
            >
              <div slot="actions" class="v-step__buttons">
                <button @click="tour.skip" class="v-step__button">
                  Skip tour
                </button>
                <button @click="tour.nextStep" class="v-step__button">
                  Next
                </button>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <div id="v-tour--active"></div>
  </div>
</template>

  <script>
import draggable from "vuedraggable";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import toolboxIcon from "@/views/management/views/settings/views/dynamic_forms/components/ToolboxIcon.vue";
import icons from "@/views/management/views/settings/views/dynamic_forms/data/icons.js";
import variantOptions from "@/views/management/views/settings/views/dynamic_forms/data/variantOptions.js";
import colOptions from "@/views/management/views/settings/views/dynamic_forms/data/colOptions.js";
import FORM_SERVICE from "@/views/management/views/settings/views/dynamic_forms/services/Form.service.js";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { v4 as uuidv4 } from "uuid";
import SwitchCustom from "@/views/management/views/settings/views/dynamic_forms/components/switch/SwitchCustom.vue";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    moduleId: {
      type: Number,
      required: true,
    },
    country: {
      required: false,
      type: String,
      default: null,
    },
  },
  components: {
    draggable,
    DragAndDrop,
    quillEditor,
    toolboxIcon,
    VueGoogleAutocomplete,
    SwitchCustom,
  },
  filters: {
    removeHyphens(value) {
      return value.replace(/[-_]/g, " ");
    },
  },
  data() {
    return {
      sections: [],
      components: [],
      component: {
        id: null,
        type_id: null,
        key: null,
        is: null,
        col: null,
        type: null,
        label: null,
        placeholder: null,
        format: false,
        options: [],
        multiple: false,
        parent: [],
        position: null,
        is_parent: false,
        validations: [],
        model: null,
        new_component: 1,
      },
      section: {
        id: null,
        title: null,
        subtitle: null,
        icon: null,
        variant: null,
        position: null,
        components: [],
        new_section: 1,
      },
      variantOptions: variantOptions,
      //component type is select
      optionsComponent: {
        id: null,
        value: 1,
        text: null,
      },
      icons: icons,
      colOptions: colOptions,
      validationsByComponentType: [], //array of validations by component type
      componentNameSelected: null, // toolbox show name of selected component
      isSection: false, // toolbox show section
      formatActive: false, //add formatting to input text
      selectSection: null, //selected section to add component
      titleSectionIsUnique: true,
      optionSaveSection: null, //1 = addNewSection, 2 = editSectionSettings
      optionSaveComponent: null, //1 = addNewComponent, 2 = editComponentSettings
      indiceEdit: null, //indice of section and component to edit
      indiceSection: null, //indice section used in edit component
      currentSection: null, //current section to add component
      componentHasOptions: false, //component has options
      validationsActive: false, //add validations to component
      conditionsActive: false, //add conditions to component
      //conditions
      parentComponents: [],
      selectParentComponent: null,
      optionsParentComponent: null,
      hasContidions: false,
      //tutorial
      tutorial: null,
      steps: [
        {
          target: '[data-v-step="0"]',
          header: {
            title: "Get Started Tutorial",
          },
          content: `Discover how to create <strong>Custom Fields</strong>`,
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="1"]',
          content: "Click the icon to create a new section",
        },
        {
          target: '[data-v-step="2"]',
          content:
            "Fill out the requested fields and observe the change displayed in the previewer. <br> * only <strong>title</strong> is required, the others are optional",
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="3"]',
          content: "Click here to build the section",
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="4"]',
          content:
            "Now to add a field to a section you can choose any of the options from this onwards but for this example click here",
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="5"]',
          content:
            "Now select the section where you want to add it, the size it will occupy and its label and see how it is built within the section, the other fields are optional",
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="3"]',
          content: "Click here to build the field",
          params: {
            placement: "top",
          },
        },
      ],
      myCallbacks: {
        onSkip: this.myCustomSkipStepCallback,
        onFinish: this.myCustomFinishCallback,
        onNextStep: this.myCustomNextStepCallback,
      },
      myOptionsTour: {
        highlight: true,
        useKeyboardNavigation: false,
      },
      //limit click in vue tour
      hasClickedStep3: false,
      hasClickedStep4: false,
      hasClickedStepFinish: false,
      isDrag: true,
      selectedCountry: "US",
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "sections",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    dragOptionsCols() {
      return {
        animation: 200,
        group: "components",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    isSettingsSection() {
      return this.$route.matched[2].meta.section == "custom-fields";
    },
  },
  created() {
    if (localStorage.getItem("mg_dinamic_forms") == null) {
      localStorage.setItem("mg_dinamic_forms", "show");
    }
    this.tutorial = localStorage.getItem("mg_dinamic_forms");
  },
  async mounted() {
    await this.GET_ALL_TYPE_COMPONENTS();
    await this.GET_CUSTOM_FORM_BY_ID();
    if (this.tutorial == "show") {
      this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
      this.$tours["myTour"].start();
      this.addAndRemoveClass("add");
    }
  },
  methods: {
    onDragEnd() {
      this.sections = this.sections.filter((section) => section.title);
      this.updatePositionComponents();
      this.updatePositionSections();
    },
    myCustomSkipStepCallback() {
      localStorage.setItem("mg_dinamic_forms", "hidden");
      this.tutorial = localStorage.getItem("mg_dinamic_forms");
      this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
      this.addAndRemoveClass("remove");
    },
    myCustomFinishCallback() {
      const step3Elements = document.querySelectorAll('[data-v-step="3"]');
      this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
      if (
        this.component.label == null ||
        this.component.col == null ||
        this.selectSection == null
      ) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "InfoIcon",
          "Please complete the requested fields"
        );
        // Error programmed to stop the tour;
        console.clear();
        throw new Error("Please complete the requested fields");
      }
      if (step3Elements.length > 0) {
        console.clear();
        step3Elements[0].click();
      }
      localStorage.setItem("mg_dinamic_forms", "hidden");
      this.tutorial = localStorage.getItem("mg_dinamic_forms");
      this.addAndRemoveClass("remove");
    },
    myCustomNextStepCallback(currentStep) {
      const step1Elements = document.querySelectorAll('[data-v-step="1"]');
      const step3Elements = document.querySelectorAll('[data-v-step="3"]');
      const step4Elements = document.querySelectorAll('[data-v-step="4"]');
      if (currentStep === 1) {
        if (step1Elements.length > 0) {
          step1Elements[0].click();
        }
      }
      if (currentStep === 3 && !this.hasClickedStep3) {
        if (this.section.title == null || this.titleSectionIsUnique == false) {
          this.showToast(
            "warning",
            "top-right",
            "Oops!",
            "InfoIcon",
            "Please complete the requested fields"
          );
          // Error programmed to stop the tour;
          console.clear();
          throw new Error("Please complete the requested fields");
        }
        if (step3Elements.length > 0) {
          console.clear();
          step3Elements[0].click();
        }
      }
      if (currentStep === 4 && !this.hasClickedStep4) {
        if (step4Elements.length > 0) {
          step4Elements[0].click();
        }
      }
    },
    addAndRemoveClass(option) {
      const footer = document.querySelector(".footer");
      const vTourActive = document.getElementById("v-tour--active");
      if (option == "add") {
        footer.classList.add("footer--v-tour-active");
        vTourActive.classList.add("v-tour--active");
      }
      if (option == "remove") {
        footer.classList.remove("footer--v-tour-active");
        vTourActive.classList.remove("v-tour--active");
      }
    },
    async GET_ALL_TYPE_COMPONENTS() {
      try {
        this.addPreloader();
        const { data } = await FORM_SERVICE.GET_ALL_TYPE_COMPONENTS();
        this.components = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async GET_VALIDATIONS_BY_COMPONENT_ID(id) {
      try {
        this.addPreloader();
        const { data } = await FORM_SERVICE.GET_VALIDATIONS_BY_COMPONENT_ID({
          type_component_id: id,
        });
        this.validationsByComponentType = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async GET_CUSTOM_FORM_BY_ID() {
      try {
        this.addPreloader();
        const { data } = await FORM_SERVICE.GET_CUSTOM_FORM_BY_ID({
          module_id: this.moduleId,
          form_id: this.id,
          country: !this.isSettingsSection
            ? this.country
            : this.selectedCountry,
        });
        if (data.length < 1) return;
        // localStorage.setItem("mg_dinamic_forms", "hidden");
        // this.tutorial = localStorage.getItem("mg_dinamic_forms");
        let forms = JSON.parse(data[0].data);
        this.sections = forms;
        this.filterParentComponentsByCondition();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async SAVE_CONFIGURATION_FORM() {
      try {
        this.addPreloader();
        await FORM_SERVICE.SAVE_CUSTOM_FORM({
          form_id: this.id,
          form: this.sections,
          country: !this.isSettingsSection
            ? this.country
            : this.selectedCountry,
        });
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Configuration saved"
        );
        await this.GET_CUSTOM_FORM_BY_ID();
        this.closeConfiguration();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    configureNewSection() {
      this.tutorial == "show" ? this.$tours["myTour"].nextStep() : "";
      this.isSection = true;
      this.componentNameSelected = "new-section";
      this.optionSaveSection = 1;
      this.$nextTick(() => {
        this.$refs.isSection.innerText = "Configure new section";
      });
    },
    saveSection(option) {
      option == 1 ? this.addNewSection() : this.addEditedSection();
      this.cancel();
    },
    addNewSection() {
      if (this.tutorial == "show") {
        this.$tours["myTour"].nextStep();
        if (this.hasClickedStep3) {
          return;
        }
        this.hasClickedStep3 = true;
      }
      this.section.position = this.sections.length + 1;
      this.sections.push(this.section);
    },
    async deleteSection(title,index) {
      const result = await this.showConfirmSwal(`Are you sure to delete the '${title}' section?`);
      if (result.isConfirmed) {
        this.sections.splice(index, 1);
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Section deleted"
        );
        this.updatePositionSections();
      }
    },
    showSectionSettings(index) {
      this.isSection = true;
      this.componentNameSelected = "new-section";
      this.optionSaveSection = 2;
      this.$nextTick(() => {
        this.$refs.isSection.innerText = "Edit section configuration";
      });
      let section = this.sections[index];
      this.selectSection = section.title;
      this.section = {
        title: section.title,
        subtitle: section.subtitle,
        icon: section.icon,
        variant: section.variant,
      };
      this.indiceEdit = index;
    },
    addEditedSection() {
      let section = this.sections[this.indiceEdit];
      section.title = this.section.title;
      section.subtitle = this.section.subtitle;
      section.icon = this.section.icon;
      section.variant = this.section.variant;
      this.showToast(
        "success",
        "top-right",
        "Success",
        "CheckIcon",
        "Section edited"
      );
      this.cancel();
    },
    configureNewComponent(id) {
      if (this.tutorial == "show") {
        this.$tours["myTour"].nextStep();
        if (this.hasClickedStep4) {
          return;
        }
        this.hasClickedStep4 = true;
      }
      if (this.sections.length < 1) {
        this.showToast(
          "info",
          "top-right",
          "Oops!",
          "InfoIcon",
          "First create a section and then add fields"
        );
        return;
      }
      let component = this.components.find((component) => component.id == id);
      this.componentNameSelected = component.name;
      this.component.type_id = component.id;
      this.component.is = component.is;
      this.component.type = component.name;
      this.component.col = 12;
      this.component.key = uuidv4();
      component.name == "checkbox" ? (this.component.model = []) : null;
      this.componentHasOptions = component.option == 0 ? false : true;
      component.name == "money" ? (this.component.format = "$") : "";
      this.$nextTick(() => {
        this.$refs.isComponent.innerText = "Configure new field";
      });
      this.optionSaveComponent = 1;
      this.component.visible = false;

      this.GET_VALIDATIONS_BY_COMPONENT_ID(component.id);
    },
    saveComponent(option) {
      option == 1 ? this.addNewComponent() : this.addEditComponent();
      this.cancel();
    },
    addNewComponent() {
      if (this.tutorial == "show") {
        const buttonFinishStep = document.querySelector(".v-step__button-stop");
        if (buttonFinishStep) {
          buttonFinishStep.click();
        }
        if (this.hasClickedStepFinish) {
          return;
        }
        this.hasClickedStepFinish = true;
      }
      let section = this.sections.find(
        (section) => section.title == this.selectSection
      );
      //add position component
      this.component.position = section.components.length + 1;
      if (this.component.type == "switch") {
        this.component.options.push({
          id: null,
          value: this.optionsComponent.value,
          text: this.component.label,
        });
        this.optionsComponent = {
          id: null,
          value: this.optionsComponent.value + 1,
          text: null,
        };
      }
      section.components.push({ ...this.component });
      this.component.is_parent ? this.filterParentComponentsByCondition() : "";
    },
    showComponetSettings(index, sectionTitle, indexSection) {
      this.cancel();
      this.filterParentComponentsByCondition();
      this.optionSaveComponent = 2;
      let component = this.sections[indexSection].components[index];
      this.componentNameSelected = component.type;
      this.selectSection = sectionTitle;
      this.section.title = sectionTitle;
      this.currentSection = sectionTitle;
      let hasOptions = this.components.find(
        (obj) => obj.name == component.type
      );
      this.componentHasOptions = hasOptions.option == 0 ? false : true;
      component.format ? (this.formatActive = true) : "";
      component.validations.length > 0
        ? (this.validationsActive = true)
        : (this.validationsActive = false);
      this.component = {
        id: component.id,
        type_id: component.type_id,
        key: component.key,
        is: component.is,
        col: component.col,
        type: component.type,
        label: component.label,
        placeholder: component.placeholder,
        format: component.format,
        options: component.options,
        multiple: component.multiple,
        parent: component.parent,
        position: component.position,
        is_parent: component.is_parent,
        validations: component.validations,
        model: null,
        new_component: component.new_component,
        visible: component.visible,
      };
      this.$nextTick(() => {
        this.$refs.isComponent.innerText = "Edit field configuration";
      });
      this.indiceEdit = index;
      this.indiceSection = indexSection;
      this.GET_VALIDATIONS_BY_COMPONENT_ID(component.type_id);
      //conditions
      if (component.parent.length > 0) {
        this.conditionsActive = true;
        this.hasContidions = true;
        this.selectParentComponent = component.parent[0].key;
        let options = this.parentComponents.filter(
          (obj) => obj.key == component.parent[0].key
        );
        this.optionsParentComponent = options[0].options;
      }
    },
    addEditComponent() {
      if (this.section.title == this.selectSection) {
        this.sections[this.indiceSection].components[this.indiceEdit] =
          this.component;
      } else {
        this.sections[this.indiceSection].components.splice(this.indiceEdit, 1);
        this.addNewComponent();
        this.updatePositionComponents();
      }
      this.showToast(
        "success",
        "top-right",
        "Success",
        "CheckIcon",
        "Field edited"
      );
      this.cancel();
      this.filterParentComponentsByCondition();
    },
    async deleteComponent(label,index, sectionIndex) {
      const result = await this.showConfirmSwal(`Are you sure to delete the '${label}' field?`);
      if (result.isConfirmed) {
        this.sections[sectionIndex].components.splice(index, 1);
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Field deleted"
        );
        this.updatePositionComponents();
        this.filterParentComponentsByCondition();
      }
    },
    addFormat() {
      this.formatActive
        ? (this.component.format = "")
        : (this.component.format = false);
    },
    cancel() {
      this.componentNameSelected = null;
      this.isSection = false;
      this.selectSection = null;
      this.formatActive = false;
      this.titleSectionIsUnique = true;
      this.optionSaveSection = null;
      this.optionSaveComponent = null;
      this.indiceEdit = null;
      this.indiceSection = null;
      this.currentSection = null;
      this.componentHasOptions = false;
      this.validationsActive = false;
      this.conditionsActive = false;
      this.optionsParentComponent = null;
      this.selectParentComponent = null;
      this.hasContidions = false;

      this.section = {
        title: null,
        subtitle: null,
        icon: null,
        variant: null,
        components: [],
        new_section: 1,
      };
      this.component = {
        id: null,
        key: null,
        is: null,
        col: null,
        type: null,
        label: null,
        placeholder: null,
        format: false,
        options: [],
        multiple: false,
        parent: [],
        position: null,
        is_parent: false,
        validations: [],
        model: null,
        new_component: 1,
      };
    },
    validateFormat() {
      const allowedCharacters = ["#", "(", ")", "-", " ", "$"];
      //remove if is not in allowedCharacters
      this.component.format = this.component.format
        .split("")
        .filter((char) => allowedCharacters.includes(char))
        .join("");
    },
    validateSectionTitle() {
      const validateTitle = this.sections.some(
        (obj, index) =>
          obj.title == this.section.title && index !== this.indiceEdit
      );
      if (validateTitle) {
        this.titleSectionIsUnique = false;
      } else {
        this.titleSectionIsUnique = true;
      }
    },
    labelComponentAcctions() {
      this.componentPlaceholderByDefault();
    },
    componentPlaceholderByDefault() {
      switch (this.component.type) {
        case "text":
        case "email":
        case "number":
        case "password":
        case "textarea":
        case "url":
        case "file":
          this.component.placeholder =
            "Insert " + this.component.label.toLowerCase();
          break;
        case "date":
          this.component.placeholder = "MM/DD/YYYY";
          break;
        case "select":
          this.component.placeholder = "Select an option";
          break;
        case "address":
          this.component.placeholder = "Please type your address";
          break;
        default:
          break;
      }
    },
    addOptionsComponent() {
      if (
        this.optionsComponent.text == null ||
        this.optionsComponent.text == ""
      ) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "InfoIcon",
          "Enter option"
        );
        return;
      }
      this.component.options.push(this.optionsComponent);
      this.optionsComponent = {
        id: null,
        value: this.optionsComponent.value + 1,
        text: null,
      };
    },
    deleteOptionsComponent(index) {
      this.component.options.splice(index, 1);
    },
    closeConfiguration() {
      this.$emit("close");
    },
    updatePositionComponents() {
      this.sections.forEach((section) => {
        section.components.forEach((component, index) => {
          component.position = index + 1;
        });
      });
    },
    updatePositionSections() {
      this.sections.forEach((section, index) => {
        section.position = index + 1;
      });
    },
    shouldRenderFormGroup(component) {
      return (
        component.type !== "money" &&
        component.format == false &&
        component.type !== "address" &&
        component.type !== "checkbox" &&
        component.type !== "radio"
      );
    },
    isRuleInValidations(rule) {
      return (
        this.component.validations.some(
          (validation) => validation.type_id == rule.id
        ) ||
        (this.formatActive && (rule.type == "min" || rule.type == "max"))
      );
    },
    discardValidations() {
      if (!this.validationsActive) {
        this.component.validations = [];
      }
    },
    addValidationsComponent(id, type) {
      let value = null;
      const validationMessages = {
        min: "Max Length cannot be less than the Min Length.",
        max: "Min Length cannot be greater than the Max Length.",
        min_value: "Max value cannot be less than the Min value.",
        max_value: "Min value cannot be greater than the Max value.",
      };
      let foundDiscrepancy = false;
      let msg = "";
      if (
        type == "min" ||
        type == "max" ||
        type == "min_value" ||
        type == "max_value"
      ) {
        value = this.$refs["ruleValue" + id][0].$el.value;
        if (value == null || value == "" || value < 1) {
          this.showToast(
            "warning",
            "top-right",
            "Oops!",
            "InfoIcon",
            "Enter value"
          );
          return;
        }

        for (const item of this.component.validations) {
          const message = validationMessages[item.type];
          if (message) {
            if (
              (item.type === "min" && parseInt(value) < parseInt(item.value)) ||
              (item.type === "max" && parseInt(value) > parseInt(item.value)) ||
              (item.type === "min_value" &&
                parseInt(value) < parseInt(item.value)) ||
              (item.type === "max_value" &&
                parseInt(value) > parseInt(item.value))
            ) {
              foundDiscrepancy = true;
              msg = message;
              break;
            }
          }
        }

        if (foundDiscrepancy) {
          this.showToast("warning", "top-right", "Oops!", "InfoIcon", `${msg}`);
          return;
        }
      }

      this.component.validations.push({
        id: null,
        type_id: id,
        type: type,
        value: value,
      });
    },
    deleteValidationsComponent(index) {
      this.component.validations.splice(index, 1);
    },
    buildRules(component) {
      const typeToRuleMap = {
        email: "email",
        url: "url",
      };

      const resultArray = component.validations.map((item) => {
        if (item.value == null) {
          return item.type;
        } else {
          return `${item.type}:${item.value}`;
        }
      });

      if (typeToRuleMap[component.type]) {
        resultArray.push(typeToRuleMap[component.type]);
      }

      return resultArray.join("|");
    },
    discardConditions() {
      if (!this.conditionsActive) {
        this.component.parent = [];
        this.optionsParentComponent = null;
        this.selectParentComponent = null;
      }
    },
    filterParentComponentsByCondition() {
      this.parentComponents = [];
      this.sections.forEach((item) => {
        if (item.components) {
          item.components.forEach((component) => {
            if (component.is_parent == true) {
              this.parentComponents.push(component);
            }
          });
        }
      });
    },
    selectedParent() {
      let options = this.parentComponents.filter(
        (obj) => obj.key == this.selectParentComponent
      );
      this.component.label = null;
      this.component.placeholder = null;
      this.component.parent = [];
      this.optionsParentComponent = null;
      this.hasContidions = false;
      if (this.component.key == this.selectParentComponent) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "InfoIcon",
          "The linked field cannot be the same"
        );
        this.selectParentComponent = null;
        return;
      }
      if (options.length > 0) {
        this.optionsParentComponent = options[0].options;
        //add parent in component
        this.component.parent.push({
          id: options[0].id,
          key: this.selectParentComponent,
          conditions: [],
        });
      }
    },
    addConditionsComponent(value) {
      let valueLabel = this.$refs["condition-" + value][0].$el.value;
      if (valueLabel == null || valueLabel == "") {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "InfoIcon",
          "Enter label"
        );
        return;
      }
      this.component.parent[0].conditions.push({
        id: null,
        id_option: value,
        label: valueLabel,
        placeholder: null,
      });
      this.hasContidions = true;
    },
    isConditionInComponent(condition) {
      return this.component.parent[0].conditions.some(
        (obj) => obj.id_option == condition.value
      );
    },
    deleteConditionComponent(index) {
      this.component.parent[0].conditions.splice(index, 1);
      this.component.parent[0].conditions.length < 1
        ? (this.hasContidions = false)
        : "";
    },
    findValueCondition(condition, component) {
      const value = condition.id_option;
      const result = this.optionsParentComponent.find(
        (option) => option.value == value
      );
      return result ? result.text : "No Match";
    },
    //show and hide components according to the parent component
    shouldShowComponent(component) {
      if (component.parent.length == 0) {
        // If it does not have a parent defined, show the component.
        return true;
      }
      return false;
    },
    //show component if it meets the condition
    componentAcctions(valueSelected, key, isParent) {
      if (isParent) {
        this.sections.forEach((section) => {
          section.components.forEach((component) => {
            if (component.parent.length > 0 && component.parent[0].key == key) {
              let shouldBreak = false;
              component.parent[0].conditions.forEach((condition) => {
                if (condition.id_option == valueSelected) {
                  component.label = condition.label;
                  component.placeholder = condition.placeholder;
                  this.$refs[component.key][0].style.setProperty(
                    "display",
                    "block"
                  );
                  shouldBreak = true;
                }
              });

              if (shouldBreak) {
                return; // get out the loop
              }

              // If shouldBreak is false
              component.label = null;
              this.$refs[component.key][0].style.setProperty(
                "display",
                "none",
                "important"
              );
            }
          });
        });
      }
    },
    getAddressData(data, placeResultData, component) {
      this.$refs[component.key][0].$el.value =
        placeResultData.formatted_address;
      component.model = placeResultData.formatted_address;
    },
    updateChecked(component, value) {
      console.log(value);
      component.visible = value;
    },
  },
};
</script>

<style lang="scss">
.mg-forms-dinamic {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 10px;

  .container {
    margin-bottom: 0 !important;
  }
  .toolbox {
    display: flex;
    flex-direction: column;
    height: 85vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3) !important;
    border-radius: 10px;

    .card {
      flex: 1;

      .title-forms-dinamic strong {
        font-size: 16px;
      }

      .fields {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
        .field {
          height: 100px;
          border: 1px solid transparent !important;
          border-radius: 4px;
          background-color: rgba(34, 41, 47, 0.1);
          transition: transform 0.3s ease-out;

          user-select: none;

          &:hover {
            transform: translateY(-4px);
            background-color: #3b82f6 !important;
            color: #fff;
          }
        }
        .field.active.animation {
          animation: 1.5s ease-in-out infinite animateComponent;
        }
        @keyframes animateComponent {
          0% {
            transform: scale(0.95);
          }
          50% {
            transform: scale(1);
          }
          100% {
            transform: scale(0.95);
          }
        }
        .active {
          background-color: #3b82f6 !important;
          color: #fff;
        }
      }
    }
  }

  .forms-dinamic {
    display: flex;
    flex-direction: column;
    height: 85vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3) !important;
    border-radius: 10px;

    .card {
      flex: 1;
    }
    .empty-forms {
      .card-body {
        display: flex;
        justify-content: center;
        align-items: center;

        .title-forms-dinamic strong {
          font-size: 16px;
        }
      }
    }
    //previsualizacion de los componentes y secciones para agregar configuracion
    .configure-section {
      height: unset;
      animation: 1.5s ease-in-out infinite animateStting;
    }
    @keyframes animateStting {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-8px);
      }
      100% {
        transform: translateY(0);
      }
    }
    //end
    .border-active {
      border: 3px dotted #611fdb !important;
    }
    .border-active-section {
      border: 3px dotted #3b82f6 !important;
    }
    .components-dinamic:hover .component-actions .yesDrag {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }

    .component-actions .yesDrag {
      opacity: 0;
    }
  }

  .ql-container {
    height: 240px !important;
  }

  //tutorial styles
  .v-step {
    background-color: #fff !important;
    border-radius: 4px !important;
    -webkit-filter: unset !important;
    filter: unset !important;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3) !important;
    .v-step__header {
      background-color: #3b82f6 !important;
      font-weight: bold !important;
    }
    .v-step__content {
      color: #636363 !important;
    }
    .v-step__arrow {
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      border-style: solid !important;
      border-color: #3b82f6 !important;
    }
    .v-step__buttons {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      .v-step__button {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        background-color: #3b82f6 !important;
        color: #fff !important;
        border-radius: 4px !important;
        border: 1px solid #3b82f6 !important;
        padding: 15px 10px !important;
        font-size: 12px !important;
        text-transform: uppercase !important;
        font-weight: bold !important;
        &:hover {
          background-color: #fff !important;
          color: #3b82f6 !important;
        }
      }
    }
  }
  .v-tour__target--highlighted {
    box-shadow: none !important;
    border: 2px solid #3b82f6;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff !important;
  }
  .v-tour--active {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}
.dark-layout .mg-forms-dinamic {
  background-color: #111113 !important;
  .toolbox {
    background-color: #17171a !important;
    box-shadow: none !important;
    .fields {
      .field {
        background-color: #1d2125 !important;
        box-shadow: none !important;
      }
      .active {
        background-color: #3b82f6 !important;
      }
    }
  }

  .forms-dinamic {
    box-shadow: none !important;
    background-color: #17171a !important;
  }
  .sortable-chosen {
    background-color: #17171a !important;
  }

  .ghost {
    background-color: #17171a !important;
  }

  //tutorial styles
  .v-step {
    background-color: #111113 !important;
    .v-step__header {
      background-color: #611fdb !important;
    }
    .v-step__content {
      color: #fff !important;
      box-shadow: none !important;
    }
    .v-step__arrow {
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      border-style: solid !important;
      border-color: #611fdb !important;
    }
    .v-step__buttons {
      .v-step__button {
        background-color: #611fdb !important;
        border: 1px solid #611fdb !important;
        color: #fff !important;
        &:hover {
          background-color: transparent !important;
          color: #611fdb !important;
        }
      }
    }
  }
  .v-tour__target--highlighted {
    box-shadow: none !important;
    border: 2px solid #611fdb;
    background-color: #17171a !important;
  }
  .v-tour--active {
    background: rgba(17, 17, 19, 0.3);
  }
}
.footer--v-tour-active {
  background-color: rgba(0, 0, 0, 0.5) !important;
  margin-top: -13px !important;
}
.dark-layout .footer--v-tour-active {
  background-color: rgba(17, 17, 19, 0.3);
}
@media (max-width: 768px) {
  .mg-forms-dinamic {
    grid-template-columns: 1fr;
    .toolbox,
    .forms-dinamic {
      height: 50vh;
    }
    .toolbox {
      grid-row: 1;
    }
    .forms-dinamic {
      grid-row: 2;
    }
  }
}

.flip-list-move {
  transition: transform 0.5s !important;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
}

// .sortable-chosen {
//   margin: 0 !important;
//   padding: 0 !important;
// }

.noDrag {
  pointer-events: none;
}

.yesDrag {
  pointer-events: auto !important;
}

.custom-checkbox-field .custom-control-label {
  padding-left: 0px !important;
}
</style>
