<template>
  <div
    class="toggle-switch-custom-control"
    v-b-tooltip.hover
    title="Filter column"
  >
    <div class="toggle-switch">
      <input
        type="checkbox"
        name="switch"
        class="toggle-switch-checkbox"
        :id="idCustom"
        :checked="isChecked"
        @change="toggleSwitch"
      />
      <label class="toggle-switch-label" :for="idCustom">
        <span class="toggle-switch-inner"></span>
        <span class="toggle-switch-switch"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    idCustom: {
      type: String,
      Number,
      required: true,
    },
  },
  methods: {
    toggleSwitch() {
      this.$emit("update:checked", !this.isChecked);
    },
  },
};
</script>

<style scoped>
.toggle-switch-custom-control {
  width: 100%;
  margin: 0;
  pointer-events: auto !important;
}

.customize-control-title {
  display: inline-block;
  font-weight: bold;
}

.toggle-switch {
  position: relative;
  width: 68px;
  float: right;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  padding: 0;
}

.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 22px;
  padding: 0;
  line-height: 22px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}

.toggle-switch-inner:before {
  content: "show";
  padding-left: 8px;
  background-color: #0090e7;
  color: #ffffff;
}

.toggle-switch-inner:after {
  content: "hide";
  padding-right: 8px;
  background-color: #545a6a;
  color: #fff;
  text-align: right;
}

.toggle-switch-switch {
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 45px;
  border-radius: 50%;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
</style>
