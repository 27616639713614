export default [
  {
    key: 'name',
    sortable: true,
    label: 'Form Name',
  },
  {
    key: 'created_by',
    sortable: true,
    label: 'Created By',
  },
  {
    key: 'created_at',
    sortable: true,
    label: 'Created At',
  },
  {
    key: 'actions',
    label: 'Actions',
    visible: true,
  },
];