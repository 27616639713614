export default [
  { label: "Blue", value: "text-primary" },
  { label: "Orange", value: "text-warning" },
  { label: "Red", value: "text-danger" },
  { label: "Purple", value: "text-info" },
  { label: "Green", value: "text-success" },
  { label: "Gray", value: "text-secondary" },
  { label: "Black", value: "text-dark" },
  { label: "White", value: "text-light" },
];
