<template>
  <div>
    <svg
      v-if="iconName === 'new-section'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-new-section"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M9 12l6 0"></path>
      <path d="M12 9l0 6"></path>
      <path
        d="M4 6v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1m-5 0h-2m-5 0h-1a1 1 0 0 1 -1 -1v-1m0 -5v-2m0 -5"
      ></path>
    </svg>

    <svg
      v-if="iconName === 'number'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-123"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M3 10l2 -2v8"></path>
      <path
        d="M9 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3"
      ></path>
      <path
        d="M17 8h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5"
      ></path>
    </svg>

    <svg
      v-if="iconName === 'email'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-at"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
      <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
    </svg>

    <svg
      v-if="iconName === 'date'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-calendar"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z"
      ></path>
      <path d="M16 3v4"></path>
      <path d="M8 3v4"></path>
      <path d="M4 11h16"></path>
      <path d="M11 15h1"></path>
      <path d="M12 15v3"></path>
    </svg>

    <svg
      v-if="iconName === 'time'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-clock-hour-3"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
      <path d="M12 12h3.5"></path>
      <path d="M12 7v5"></path>
    </svg>

    <svg
      v-if="iconName === 'file'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-file"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
      <path
        d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
      ></path>
    </svg>

    <svg
      v-if="iconName === 'password'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-password"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 10v4"></path>
      <path d="M10 13l4 -2"></path>
      <path d="M10 11l4 2"></path>
      <path d="M5 10v4"></path>
      <path d="M3 13l4 -2"></path>
      <path d="M3 11l4 2"></path>
      <path d="M19 10v4"></path>
      <path d="M17 13l4 -2"></path>
      <path d="M17 11l4 2"></path>
    </svg>

    <svg
      v-if="iconName === 'select'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-select"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
      ></path>
      <path d="M9 11l3 3l3 -3"></path>
    </svg>

    <svg
      v-if="iconName === 'checkbox'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-checkbox"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M9 11l3 3l8 -8"></path>
      <path
        d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"
      ></path>
    </svg>

    <svg
      v-if="iconName === 'money'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-currency-dollar"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"
      ></path>
      <path d="M12 3v3m0 12v3"></path>
    </svg>

    <svg
      v-if="iconName === 'switch'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-toggle-left"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
      <path
        d="M2 6m0 6a6 6 0 0 1 6 -6h8a6 6 0 0 1 6 6v0a6 6 0 0 1 -6 6h-8a6 6 0 0 1 -6 -6z"
      ></path>
    </svg>

    <svg
      v-if="iconName === 'url'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-link"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M9 15l6 -6"></path>
      <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"></path>
      <path
        d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463"
      ></path>
    </svg>

    <svg
      v-if="iconName === 'drag_and_drop'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-drag-drop-2"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"
      ></path>
      <path d="M4 4l0 .01"></path>
      <path d="M8 4l0 .01"></path>
      <path d="M12 4l0 .01"></path>
      <path d="M16 4l0 .01"></path>
      <path d="M4 8l0 .01"></path>
      <path d="M4 12l0 .01"></path>
      <path d="M4 16l0 .01"></path>
    </svg>

    <svg
      v-if="iconName === 'text'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-text-size"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M3 7v-2h13v2"></path>
      <path d="M10 5v14"></path>
      <path d="M12 19h-4"></path>
      <path d="M15 13v-1h6v1"></path>
      <path d="M18 12v7"></path>
      <path d="M17 19h2"></path>
    </svg>

    <svg
      v-if="iconName === 'textarea'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-align-center"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M4 6l16 0"></path>
      <path d="M8 12l8 0"></path>
      <path d="M6 18l12 0"></path>
    </svg>

    <svg
      v-if="iconName === 'quill_editor'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-h-1"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M19 18v-8l-2 2"></path>
      <path d="M4 6v12"></path>
      <path d="M12 6v12"></path>
      <path d="M11 18h2"></path>
      <path d="M3 18h2"></path>
      <path d="M4 12h8"></path>
      <path d="M3 6h2"></path>
      <path d="M11 6h2"></path>
    </svg>

    <svg
      v-if="iconName === 'address'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-map-pin-2"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 18.5l-3 -1.5l-6 3v-13l6 -3l6 3l6 -3v7"></path>
      <path d="M9 4v13"></path>
      <path d="M15 7v5"></path>
      <path
        d="M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z"
      ></path>
      <path d="M19 18v.01"></path>
    </svg>

    <svg
      v-if="iconName === 'radio'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-circle-filled"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M7 3.34a10 10 0 1 1 -4.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 4.995 -8.336z"
        stroke-width="0"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "toolboxIcon",
  props: {
    iconName: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
