export default [
  "ActivityIcon",
  "AirplayIcon",
  "AlertCircleIcon",
  "AlertOctagonIcon",
  "AlertTriangleIcon",
  "AlignCenterIcon",
  "AlignJustifyIcon",
  "AlignLeftIcon",
  "AlignRightIcon",
  "AnchorIcon",
  "ApertureIcon",
  "ArchiveIcon",
  "ArrowDownCircleIcon",
  "ArrowDownLeftIcon",
  "ArrowDownRightIcon",
  "ArrowDownIcon",
  "ArrowLeftCircleIcon",
  "ArrowLeftIcon",
  "ArrowRightCircleIcon",
  "ArrowRightIcon",
  "ArrowUpCircleIcon",
  "ArrowUpLeftIcon",
  "ArrowUpRightIcon",
  "ArrowUpIcon",
  "AtSignIcon",
  "AwardIcon",
  "BarChart2Icon",
  "BarChartIcon",
  "BatteryChargingIcon",
  "BatteryIcon",
  "BellOffIcon",
  "BellIcon",
  "BluetoothIcon",
  "BoldIcon",
  "BookOpenIcon",
  "BookIcon",
  "BookmarkIcon",
  "BoxIcon",
  "BriefcaseIcon",
  "CalendarIcon",
  "CameraOffIcon",
  "CameraIcon",
  "CastIcon",
  "CheckCircleIcon",
  "CheckSquareIcon",
  "CheckIcon",
  "ChevronDownIcon",
  "ChevronLeftIcon",
  "ChevronRightIcon",
  "ChevronUpIcon",
  "ChevronsDownIcon",
  "ChevronsLeftIcon",
  "ChevronsRightIcon",
  "ChevronsUpIcon",
  "ChromeIcon",
  "CircleIcon",
  "ClipboardIcon",
  "ClockIcon",
  "CloudDrizzleIcon",
  "CloudLightningIcon",
  "CloudOffIcon",
  "CloudRainIcon",
  "CloudSnowIcon",
  "CloudIcon",
  "CodeIcon",
  "CodepenIcon",
  "CodesandboxIcon",
  "CoffeeIcon",
  "ColumnsIcon",
  "CommandIcon",
  "CompassIcon",
  "CopyIcon",
  "CornerDownLeftIcon",
  "CornerDownRightIcon",
  "CornerLeftDownIcon",
  "CornerLeftUpIcon",
  "CornerRightDownIcon",
  "CornerRightUpIcon",
  "CornerUpLeftIcon",
  "CornerUpRightIcon",
  "CpuIcon",
  "CreditCardIcon",
  "CropIcon",
  "CrosshairIcon",
  "DatabaseIcon",
  "DeleteIcon",
  "DiscIcon",
  "DivideCircleIcon",
  "DivideSquareIcon",
  "DivideIcon",
  "DollarSignIcon",
  "DownloadCloudIcon",
  "DownloadIcon",
  "DribbbleIcon",
  "DropletIcon",
  "Edit2Icon",
  "Edit3Icon",
  "EditIcon",
  "ExternalLinkIcon",
  "EyeOffIcon",
  "EyeIcon",
  "FacebookIcon",
  "FastForwardIcon",
  "FeatherIcon",
  "FigmaIcon",
  "FileMinusIcon",
  "FilePlusIcon",
  "FileTextIcon",
  "FileIcon",
  "FilmIcon",
  "FilterIcon",
  "FlagIcon",
  "FolderMinusIcon",
  "FolderPlusIcon",
  "FolderIcon",
  "FramerIcon",
  "FrownIcon",
  "GiftIcon",
  "GitBranchIcon",
  "GitCommitIcon",
  "GitMergeIcon",
  "GitPullRequestIcon",
  "GithubIcon",
  "GitlabIcon",
  "GlobeIcon",
  "GridIcon",
  "HardDriveIcon",
  "HashIcon",
  "HeadphonesIcon",
  "HeartIcon",
  "HelpCircleIcon",
  "HexagonIcon",
  "HomeIcon",
  "ImageIcon",
  "InboxIcon",
  "InfoIcon",
  "InstagramIcon",
  "ItalicIcon",
  "KeyIcon",
  "LayersIcon",
  "LayoutIcon",
  "LifeBuoyIcon",
  "Link2Icon",
  "LinkIcon",
  "LinkedinIcon",
  "ListIcon",
  "LoaderIcon",
  "LockIcon",
  "LogInIcon",
  "LogOutIcon",
  "MailIcon",
  "MapPinIcon",
  "MapIcon",
  "Maximize2Icon",
  "MaximizeIcon",
  "MehIcon",
  "MenuIcon",
  "MessageCircleIcon",
  "MessageSquareIcon",
  "MicOffIcon",
  "MicIcon",
  "Minimize2Icon",
  "MinimizeIcon",
  "MinusCircleIcon",
  "MinusSquareIcon",
  "MinusIcon",
  "MonitorIcon",
  "MoonIcon",
  "MoreHorizontalIcon",
  "MoreVerticalIcon",
  "MousePointerIcon",
  "MoveIcon",
  "MusicIcon",
  "Navigation2Icon",
  "NavigationIcon",
  "OctagonIcon",
  "PackageIcon",
  "PaperclipIcon",
  "PauseCircleIcon",
  "PauseIcon",
  "PenToolIcon",
  "PercentIcon",
  "PhoneCallIcon",
  "PhoneForwardedIcon",
  "PhoneIncomingIcon",
  "PhoneMissedIcon",
  "PhoneOffIcon",
  "PhoneOutgoingIcon",
  "PhoneIcon",
  "PieChartIcon",
  "PlayCircleIcon",
  "PlayIcon",
  "PlusCircleIcon",
  "PlusSquareIcon",
  "PlusIcon",
  "PocketIcon",
  "PowerIcon",
  "PrinterIcon",
  "RadioIcon",
  "RefreshCcwIcon",
  "RefreshCwIcon",
  "RepeatIcon",
  "RewindIcon",
  "RotateCcwIcon",
  "RotateCwIcon",
  "RssIcon",
  "SaveIcon",
  "ScissorsIcon",
  "SearchIcon",
  "SendIcon",
  "ServerIcon",
  "SettingsIcon",
  "Share2Icon",
  "ShareIcon",
  "ShieldOffIcon",
  "ShieldIcon",
  "ShoppingBagIcon",
  "ShoppingCartIcon",
  "ShuffleIcon",
  "SidebarIcon",
  "SkipBackIcon",
  "SkipForwardIcon",
  "SlackIcon",
  "SlashIcon",
  "SlidersIcon",
  "SmartphoneIcon",
  "SmileIcon",
  "SpeakerIcon",
  "SquareIcon",
  "StarIcon",
  "StopCircleIcon",
  "SunIcon",
  "SunriseIcon",
  "SunsetIcon",
  "TabletIcon",
  "TagIcon",
  "TargetIcon",
  "TerminalIcon",
  "ThermometerIcon",
  "ThumbsDownIcon",
  "ThumbsUpIcon",
  "ToggleLeftIcon",
  "ToggleRightIcon",
  "ToolIcon",
  "Trash2Icon",
  "TrashIcon",
  "TrelloIcon",
  "TrendingDownIcon",
  "TrendingUpIcon",
  "TriangleIcon",
  "TruckIcon",
  "TvIcon",
  "TwitchIcon",
  "TwitterIcon",
  "TypeIcon",
  "UmbrellaIcon",
  "UnderlineIcon",
  "UnlockIcon",
  "UploadCloudIcon",
  "UploadIcon",
  "UserCheckIcon",
  "UserMinusIcon",
  "UserPlusIcon",
  "UserXIcon",
  "UserIcon",
  "UsersIcon",
  "VideoOffIcon",
  "VideoIcon",
  "VoicemailIcon",
  "Volume1Icon",
  "Volume2Icon",
  "VolumeXIcon",
  "VolumeIcon",
  "WatchIcon",
  "WifiOffIcon",
  "WifiIcon",
  "WindIcon",
  "XCircleIcon",
  "XOctagonIcon",
  "XSquareIcon",
  "XIcon",
  "YoutubeIcon",
  "ZapOffIcon",
  "ZapIcon",
  "ZoomInIcon",
  "ZoomOutIcon",
  "MessengerIcon",
  "ComissionsIcon",
  "UsersAmgIcon",
  "AmgIcon",
  "DashboardIcon",
  "QuestionIcon",
  "PhoneSlashIcon",
  "ThListIcon",
  "Edit2SlashIcon",
  "OtherSourcesIcon",
  "ValidationInformationIcon",
  "CRInvalidIcon",
  "FilePdfIcon",
  "FileExcelIcon",
  "FileCsvIcon",
  "FilePowerpointIcon",
  "FileWordIcon",
  "FileChartIcon",
].map((icon) => ({ label: icon, value: icon }));
